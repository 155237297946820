<!--
Copyright © MindMaze Group SA 2021.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-container">
                <img id="logo-img" alt="logo"
                     src="@/assets/images/logo-big.png" />
                <form v-show="!showRecoverInstructions" name="form" @submit.prevent="requestPasswordRecovery">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input v-model="email"
                               v-validate="'required|email'"
                               type="text"
                               class="form-control"
                               name="email" />
                        <div v-if="errors.has('email')"
                             class="alert alert-danger"
                             role="alert">Email is required!</div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Request password reset</span>
                        </button>
                    </div>
                    <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                    </div>
                </form>
                  <div v-show="showRecoverInstructions" class="form-group">
                        You will receive further instructions in your email address in a few minutes.
                        Please, check the spam folder too.
                  </div>
            </div>
        </div>
    </div>
</template>

<script>

import ApiService from '@/services/api.service';

export default {
  name: 'RecoverPassword',
  data() {
    return {
      email: '',
      loading: false,
      showRecoverInstructions: false, 
      message: ''
    };
  },
  methods: {
    requestPasswordRecovery() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          if (this.email) {
            this.loading = true;
            ApiService.recoverPassword(this.email).then(
            () => {
              this.showRecoverInstructions = true
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
          this.loading = false;
        }
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: auto;
    margin-top: 8%;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-group {
    margin-top: 20px;
}
</style>